define('diversionNotifier', ['queryString'], function(queryString) {

  

  var DiversionNotifier = function() {

    var self = this;

    var _config = {
      cssClass: 'diversionNotifier_notification',
      hiddenCssClass: 'diversionNotifier_notification-hidden',
      // Roughly tranlated: We are sorry to inform you that technical problems have arisen on our Hungarian website so we have redirected it to our English website where you can still place orders in Hungary for the Forint currency, the usual shipping fees, the excellent prices and discounts you can expect from us. We will soon return to our site
      message: 'Sajnálattal tájékoztatjuk, hogy a magyar honlapunkon technikai problémák merültek fel, ezért azt átirányítottuk az angol nyelvű honlapunkra, ahol változatlanul Forint pénznemben, a megszokott szállítási díjakon, a tőlünk elvárható kiváló árakon és kedvezményekkel tudja megrendeléseit leadni Magyarországra. Hamarosan visszavárjuk magyar oldalunkon!',
      timeout: 5000
    };

    var _showNotification = function() {
      self.notifier = document.createElement('div');
      self.notifier.innerText = self.config.message;
      self.notifier.classList.add(self.config.cssClass);
      document.body.appendChild(self.notifier);
      setTimeout(self.dismissNotification, self.config.timeout);
    };

    var _dismissNotification = function() {
      self.notifier.classList.add(self.config.hiddenCssClass);
    };

    var _init = function() {
      var qs = queryString.parse(window.location.search);
      if (qs.divert && qs.divert.toUpperCase() === 'HU') {
        self.showNotification();
      }
      return self;
    };

    self.config = _config;
    self.notifier = {};
    self.showNotification = _showNotification;
    self.dismissNotification = _dismissNotification;
    self.init = _init;
  };

  return new DiversionNotifier().init();
});
